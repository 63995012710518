
import { Component, Vue } from "vue-property-decorator";
import TreeTable from "@/components/trialtable/TreeTable.vue";
import Header from "@/components/header.vue"; // @ is an alias to /src
import Loader from "@/components/loader.vue"; // @ is an alias to /src
import reportServices from "@/services/reports-service";
import FilterComponent from "@/components/sidebar-employee-filter.vue"
import FilterService from "@/services/filter-service"
import accountService from "@/services/account-service";
import SidebarTrialBalance from "@/components/trialtable/sidebar-trial-balance.vue"
@Component({
  components: { TreeTable, Header, Loader, FilterComponent, SidebarTrialBalance },
})
export default class ChartsOfAccounts extends Vue {
  loading = true;
  tableData: any = [];
  total: any = {};
  id: any = '';
  public account_selected_from: any = {};
  public account_selected_to: any = {};
  public account_selected: any = {};
  public options_account_from = [];
  public options_account_to = [];
  public options_account = [];
  public level_selected_from = {};
  public level_selected_to = {};
  toggleSelection = null;
  checked = false;
  public flattenedData: any = [];
  widths = [100, 220, 120, 120, 120, 120, 120, 120]
  columns = [
    {
      label: "Code",
      id: "code",
    },
    {
      label: "Account",
      id: "account_title",
    },
    {
      label: "Opening Balance Debit",
      id: "opening_balance_debit",
    },
    {
      label: "Opening Balance Credit",
      id: "opening_balance_credit",
    },
    {
      label: "Transaction Debit",
      id: "transaction_debit",
    },
    {
      label: "Transaction Credit",
      id: "transaction_credit",
    },
    {
      label: "Debit Close",
      id: "debit_close",
    },
    {
      label: "Credit Close",
      id: "credit_close",
    },
  ];
  columnsData = [
    {
      label: "Code",
      field: "code",
    },
    {
      label: "Account",
      field: "account_title",
    },
    {
      label: "Opening Balance Debit",
      field: "opening_balance_debit",
    },
    {
      label: "Opening Balance Credit",
      field: "opening_balance_credit",
    },
    {
      label: "Transaction Debit",
      field: "transaction_debit",
    },
    {
      label: "Transaction Credit",
      field: "transaction_credit",
    },
    {
      label: "Debit Close",
      field: "debit_close",
    },
    {
      label: "Credit Close",
      field: "credit_close",
    },
  ];
  // tableData = [
  //   {
  //     name: "Ziuta",
  //     surname: "Kozak",
  //     children: [{ name: "Czerwony Kapturek", surname: "Kozak" }],
  //   },
  //   {
  //     name: "Koziolek",
  //     surname: "Matolek",
  //     children: [
  //       {
  //         name: "Timon",
  //         surname: "Matolek",
  //         children: [{ name: "Timon Junior", surname: "Matolek" }],
  //       },
  //     ],
  //   },
  //   { name: "Pumba", surname: "unknown" },
  // ];
  // columns = [
  //   { label: "Name", id: "name" },
  //   { label: "Surname", id: "surname" },
  //   { label: "Surname", id: "surname" },
  //   { label: "Surname", id: "surname" },
  //   { label: "Surname", id: "surname" },
  //   { label: "Surname", id: "surname" },
  //   { label: "Surname", id: "surname" },
  //   { label: "Surname", id: "surname" },
  // ];
  // data: any = null;
  // selected_items: any = [];
  // total_balances: any = {
  //   total_debit_opening_balance: 0,
  //   total_credit_opening_balance: 0,
  //   total_debit_transaction: 0,
  //   total_credit_transactions: 0,
  //   total_debit_closing_balance: 0,
  //   total_credit_closing_balance: 0,
  // };
  // trial_balances: any = {};
  // header = {
  //   account_title: "",
  //   credit_close: "",
  //   debit_close: "",
  //   opening_balance_credit: "",
  //   opening_balance_debit: "",
  //   transaction_credit: "",
  //   transaction_debit: "",
  // };

  // onRowClicked(item: any, index: any, event: any) {}
  // onClick(params: any) {
  //   this.header = this.trial_balances[params.id];
  //   const filteredObjects = Object.values(this.trial_balances).filter(
  //     (obj) => (obj as { pid: number })["pid"] === params.id
  //   );

  //   this.total_balances = {
  //     total_debit_opening_balance: 0,
  //     total_credit_opening_balance: 0,
  //     total_debit_transaction: 0,
  //     total_credit_transactions: 0,
  //     total_debit_closing_balance: 0,
  //     total_credit_closing_balance: 0,
  //   };
  //   for (const obj of filteredObjects as {
  //     opening_balance_debit: number;
  //     opening_balance_credit: number;
  //     transaction_debit: number;
  //     transaction_credit: number;
  //     debit_close: number;
  //     credit_close: number;
  //   }[]) {
  //     this.total_balances.total_debit_opening_balance +=
  //       obj.opening_balance_debit;
  //     this.total_balances.total_credit_opening_balance +=
  //       obj.opening_balance_credit;
  //     this.total_balances.total_debit_transaction += obj.transaction_debit;
  //     this.total_balances.total_credit_transactions += obj.transaction_credit;
  //     this.total_balances.total_debit_closing_balance += obj.debit_close;
  //     this.total_balances.total_credit_closing_balance += obj.credit_close;
  //   }

  //   this.selected_items = filteredObjects;
  // }

  mounted() {
    this.retreive();
  }
  retreive() {
    // accountService
    // .getAccountInHierarchy()
    // .then((res) => {
    //   this.tableData = new Tree(res.data);
    // })
    // .catch((err) => {
    //   console.log(err);
    // });
    FilterService.getTrialBalance({}).then((res) => {
      if (this.tableData.length === 0) {
        // console.log("no");
      }
      this.loading = true;
      this.tableData = res.data.data;
      this.total = res.data.total;
      this.flattenData(this.tableData);
      if (this.tableData.length > 0) {
        // console.log("yes");
      }
      this.loading = false;
    })
      .catch((e) => {
        console.log(e);
        this.loading = false;
      });
    // reportServices.trailbalance().then((res) => {
    //   if (this.tableData.length === 0) {
    //     console.log("no");
    //   }
    //   this.loading = true;
    //   this.tableData = res.data.data;
    //   this.total = res.data.total
    //   // this.id = res.data.uuid;
    //   // console.log(this.id)
    //   // if (Array.isArray(res.data)) {
    //   //   this.tableData = res.data;
    //   // } else {
    //   //   this.tableData = [res.data];
    //   // }
    //   if (this.tableData.length > 0) {
    //     console.log("yes");
    //   }
    //   this.loading = false;
    //   // console.log(this.tableData);
    //   // if (Array.isArray(res.data)) {
    //   //   this.tableData = res.data;
    //   // } else {
    //   //   this.tableData = [res.data];
    //   // }
    //   // console.log(res.data);
    //   // console.log(this.tableData.res.data);
    // });
  }

  // refreshFields() {
  //   this.account_selected_from = {};
  //   this.account_selected_to = {};
  //   this.level_selected_from = {};
  //   this.level_selected_to = {};

  // }
  itemId = 0;
  onRowClicked(item: any) {
    // console.log('clicked')
    // console.log('item:', item);
    this.itemId = item.id;
    // console.log(this.itemId);
    this.$root.$emit("bv::toggle::collapse", "sidebar-variant-trial-balance");
  }
  get_parent_account(data: any, type: string) {
    if (type == "from") {
      this.account_selected_from = data;
      this.options_account_from = [];
    } else if (type == "to") {
      this.account_selected_to = data;
      this.options_account_to = [];
    } else if (type == "simple") {
      this.account_selected = data;
      this.options_account = [];
    }
  }

  search_parent_account(title: any, type: string) {
    if (title.length > 2) {
      accountService
        .getByTitleMaster(title)
        .then((response) => {
          if (type == "from") {
            this.options_account_from = response.data.result;
          } else if (type == "to") {
            this.options_account_to = response.data.result;
          }
          else if (type == "simple") {
            this.options_account = response.data.result;
          }
        })
        .catch((e) => {
          console.log(e);
        });
    }
    if (title.length == 0) {
      this.options_account_from = [];
      this.options_account_to = [];
      this.options_account = [];
    }
  }
  flattenData(data: any, parentCode = null, level = 0) {
    for (const item of data) {
      // Add flattened item to the export data
      this.flattenedData.push({
        code: item.code,
        account_title: item.account_title,
        opening_balance_debit: item.opening_balance_debit,
        opening_balance_credit: item.opening_balance_credit,
        transaction_debit: item.transaction_debit,
        transaction_credit: item.transaction_credit,
        debit_close: item.debit_close,
        credit_close: item.credit_close,
      });

      // Recursively process children
      if (item.children && item.children.length > 0) {
        this.flattenData(item.children, item.code, level + 1);
      }
    }
  }
  filter_fields = [
    { type: "text", label: "Account Title", name: "account_title", value: "", param_name: "account_title", filter_type: "simple", },
    { type: "number", label: "Code", name: "code", value: "", param_name: "code", filter_type: "simple", },
    { type: "number", label: "Level", name: "level", value: "", param_name: "level", filter_type: "simple", },
    // { type: "text", label: "Account Title", name: "account_title", value: this.account_selected_from.title, param_name: "account_title", filter_type: "simple", },
  ];
  advance_filter_fields = [
    { type: "text", label: "Account Title From", name: "min_account_title", value: "", param_name: "start_account_title", filter_type: "advance" },
    { type: "text", label: "Account Title To", name: "max_account_title", value: "", param_name: "end_account_title", filter_type: "advance" },
    { type: "number", label: "Code From", name: "min_code", value: "", param_name: "start_code", filter_type: "advance" },
    { type: "number", label: "Code To", name: "max_code", value: "", param_name: "end_code", filter_type: "advance" },
    { type: "number", label: "Level From", name: "min_level", value: "", param_name: "level_range_start", filter_type: "advance" },
    { type: "number", label: "Level To", name: "max_level", value: "", param_name: "level_range_end", filter_type: "advance" },
  ];
  handleFilterChange(element: any) {
    if (element.filter_type === "simple") {
      interface SimpleObj {
        [key: string]: any;
      }
      const simpleFilteredObj: SimpleObj = {};
      this.filter_fields.forEach((filter) => {
        // if (filter.name === "account_title") {
        //   filter.value = this.account_selected?.title || "";
        // }
        simpleFilteredObj[filter.param_name] = filter.value;
      });
      this.loading = true;
      FilterService.getTrialBalance(simpleFilteredObj).then((res) => {
        if (
          res.data.data !== undefined &&
          res.data.data !== null
        ) {
          this.tableData = res.data.data;
          this.total = res.data.total;
          this.loading = false;
          this.flattenedData = [];
          const tableDataArray = [];
          for (const key in this.tableData) {
            if (this.tableData.hasOwnProperty(key)) {
              tableDataArray.push(this.tableData[key]);
            }
          }
          this.flattenData(tableDataArray);
        } else {
          this.retreive();
        }
      });
    }
    else if (element.filter_type === "advance") {
      interface AdvanceObj {
        [key: string]: any;
      }
      const advanceFilteredObj: AdvanceObj = {};
      this.advance_filter_fields.forEach((filter) => {
        // if (filter.name === "min_account_title") {
        //   filter.value = this.account_selected_from?.title || "";
        // }
        // else if (filter.name === "max_account_title") {
        //   filter.value = this.account_selected_to?.title || "";
        // }
        advanceFilteredObj[filter.param_name] = filter.value;
      });
      this.loading = true;
      FilterService.getTrialBalance(advanceFilteredObj).then((res) => {
        if (
          res.data.data !== undefined &&
          res.data.data !== null
        ) {
          this.tableData = res.data.data;
          this.total = res.data.total;
          this.loading = false;
          this.flattenedData = [];
          const tableDataArray = [];
          for (const key in this.tableData) {
            if (this.tableData.hasOwnProperty(key)) {
              tableDataArray.push(this.tableData[key]);
            }
          }
          this.flattenData(tableDataArray);
        } else {
          this.retreive();
        }
      });
    }
  }
  refreshFilters() {
    this.toggleSelection = null
    for (let field of this.filter_fields) {
      field.value = "";
      this.account_selected.title = "";
    }
    this.retreive();
  }
  refreshAdvanceFilters() {
    for (let field of this.advance_filter_fields) {
      if (field.type == "text") {
        field.value = "";
        this.account_selected_from.title = "";
        this.account_selected_to.title = "";
      }
    }
    this.retreive();
  }
}
