
import { Component, Prop, Vue, Watch } from "vue-property-decorator";
import Sidebar from "@/components/left-sidebar.vue"; // @ is an alias to /src
import Loader from "@/components/loader.vue"; // @ is an alias to /src
import reportServices from "@/services/reports-service";

@Component({
    components: {
        Sidebar,
        Loader,
    },
})
export default class SidebarTrialBalance extends Vue {
    public item: any = {};

    @Prop({ type: Number }) id!: number;
    get_data(id: any) {
        reportServices.gettrialbalancebyid(id)
            .then((response) => {
                this.item = response.data;
            })
            .catch((e) => {
                console.log(e);
            });
    }
}
